import { Link } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { Header } from "semantic-ui-react";
import Layout from "../components/Layout";
import { MainHeader } from "../helpers/styledComponents";

export default () => (
  <div>
    <Helmet>
      <title>Not Found | Full Story Initiative</title>
      <meta name="title" content="Not Found | Full Story Initiative" />
      <meta name="og:title" content="Not Found | Full Story Initiative" />
      <meta name="twitter:title" content="Not Found | Full Story Initiative" />
      <meta name="og:site_name" content="Not Found | Full Story Initiative" />
    </Helmet>
    <Layout>
      <MainHeader>
        <Header className="h1 compact" content="PAGE NOT FOUND" />
        <Header className="h3 dark">
          <Link to="/">Back to HomePage</Link>
        </Header>
      </MainHeader>

    </Layout>
  </div>
);
